<template>
  <div class="potential">
    <rxNavBar :title="navTitle"></rxNavBar>
    <p class="application_time">申请时间：<span>{{baseInfo.addTime}}</span></p>
    <div class="panel">
      <div class="state">{{baseInfo.approvalStatusStr}}</div>
      <div class="title">
        <p class="van-multi-ellipsis--l2">{{baseInfo.roomDetailedAddress}}</p>
      </div>
      <van-divider />
      <van-row class="info">
        <van-col span="12">
          <p>合同编号：<span>{{baseInfo.contractCode}}</span></p>
        </van-col>
        <van-col span="12">
          <p>收房年限：<span>{{baseInfo.contractYears}}年</span></p>
        </van-col>
        <van-col span="12">
          <p>业主姓名：<span>{{baseInfo.ownerName}}</span></p>
        </van-col>
        <van-col span="12">
          <p>面积：<span>{{baseInfo.obuiltUpArea}}㎡</span></p>
        </van-col>
      </van-row>
    </div>
    <p class="subtitle">申请详情</p>
    <div class="panel">
      <van-row class="info">
        <van-col span="12">
          <p>申请人：<span>{{decorationInfo.applicant}}</span></p>
        </van-col>
        <van-col span="12">
          <p>装修类型：<span>{{baseInfo.renovationType}}</span></p>
        </van-col>
        <van-col span="12">
          <p>电话：<span>{{decorationInfo.applicantMobile}}</span></p>
        </van-col>
        <van-col span="12">
          <p>装修预算：<span>{{baseInfo.rentMoney ?  baseInfo.rentMoney+'元' : '暂无'}}</span></p>
        </van-col>
        <van-col span="12">
          <p>交房日期：<span>{{baseInfo.giveHouseTime}}</span></p>
        </van-col>
        <van-col span="12">
          <p>户型改造：<span>{{baseInfo.bedroomCount}}</span><span>{{'+' + baseInfo.changeCount}}</span></p>
        </van-col>
      </van-row>
    </div>
    <div class="panel withMargin">
      <p class="title_des">装修需求：</p>
      <p  class="describe">{{baseInfo.renovationNeed}}</p>
    </div>
    <div class="panel withMargin">
      <p class="title_des">装修描述：</p>
      <p  class="describe">{{baseInfo.afterRentDesprition}}</p>
    </div>
    <p class="subtitle">方案详情</p>
    <div class="panel stylistDiv">
      <p>施工负责人：<span>{{decorationInfo.chargeGlobaluserName}}</span><span>{{'  '+decorationInfo.chargeGlobaluserNameMobile}}</span></p>
      <p style="paddingBottom:17px;padding-top:3.5px">设计师：<span class="stylist">{{decorationInfo.designGlobaluserName}}</span><span style="marginLeft:5px">{{decorationInfo.designGlobaluserNameMobile}}</span></p>
    </div>
    <div class="panel withMargin" v-if="imagesList.length!=0" >
      <div class="swiper_show"  ref="wrapperBox">
        <div class="content">
          <div class="imgWrap" v-for="(url,i) in imagesList" @click="previewImg(i)">
            <van-image
              width="100"
              height="75"
              fit="contain"
              radius="10"
              :src="url"
            />
          </div>
        </div>
        <div class="num">
          {{imagesList.length}}图
        </div>
      </div>
    </div>
    <div class="panel" style="margin-top: 0.5rem;margin-bottom:0.5rem;">
      <van-tabs v-model="active"
        line-width="20"
        line-height="5"
        title-active-color="rgb(255,81,45)"
        swipeable
        sticky>
        <van-tab title="硬装方案">
          <!-- 硬装方案 -->
          <van-row class="info noPadding">
            <van-col span="12">
              <p>预计时长：<span>{{decorationInfo.hardAppointmentTimeLong}}天</span></p>
            </van-col>
            <van-col span="12">
              <p>预计费用：<span>{{decorationInfo.handBudgetFee}}元</span></p>
            </van-col>
            <van-col span="24">
              <p>预计日期：<span>{{decorationInfo.softAppointmentBeginTime}}至{{decorationInfo.softAppointmentEndTime}}</span></p>
            </van-col>
            <van-col span="24">
              <p>硬装负责人：<span>{{decorationInfo.hardChargeName}}</span></p>
            </van-col>
          </van-row>
          <van-divider />
          <div class="t_table">
            <van-row>
              <van-col span="1" class="t_header"></van-col>
              <van-col span="10" class="t_header">项目名称</van-col>
              <van-col span="6" class="t_header">数量</van-col>
              <van-col span="6" class="t_header">预计费用</van-col>
              <van-col span="1" class="t_header"></van-col>
            </van-row>
            <div class="t_body" v-for="items in hardwareList" :key="items.id">
              <van-row>
                <span class="diamod"></span>
                <span class="t_title">{{items.goodsAllocationClassName}}</span>
<!--                <van-col span="24" class="t_title">{{items.goodsAllocationClassName}}</van-col>-->
                <van-row v-for="item in items.childrenList" :key="item.id">
                  <van-col span="1" class="t_td"></van-col>
                  <van-col span="10" class="t_td">{{item.itemName}}</van-col>
                  <van-col span="6" class="t_td_1">{{item.totalCount}}</van-col>
                  <van-col span="6" class="t_td">{{item.estimateMoney ? item.estimateMoney+'元' :''}}</van-col>
                  <van-col span="1" class="t_td"></van-col>
                </van-row>
              </van-row>
            </div>

          </div>
        </van-tab>
        <van-tab title="软装方案">
          <van-row class="info noPadding">
            <van-col span="12">
              <p>预计时长：<span>{{decorationInfo.softAppointmentTimeLong}}天</span></p>
            </van-col>
            <van-col span="12">
              <p>预计费用：<span>{{decorationInfo.softBudgetFee}}元</span></p>
            </van-col>
            <van-col span="24">
              <p>预计日期：<span>{{decorationInfo.softAppointmentBeginTime}}至{{decorationInfo.softAppointmentEndTime}}</span></p>
            </van-col>
            <van-col span="24">
              <p>软装负责人：<span>{{decorationInfo.softChargeName}}</span></p>
            </van-col>
          </van-row>
          <van-divider />
          <div class="t_table">
            <van-row>
              <van-col span="1" class="t_header"></van-col>
              <van-col span="10" class="t_header">项目名称</van-col>
              <van-col span="4" class="t_header">分类</van-col>
              <van-col span="4" class="t_header">数量</van-col>
              <van-col span="4" class="t_header">预计费用</van-col>
              <van-col span="1" class="t_header"></van-col>
            </van-row>
            <div class="t_body" v-for="items in softwareList" :key="items.id">
              <van-row>
                <span class="diamod"></span>
                <span class="t_title">{{items.roomName}}</span>
<!--                <van-col span="24" class="t_title">{{items.roomName}}</van-col>-->
                <van-row v-for="item in items.childrenList" :key="item.id" >
                  <van-col span="1" class="t_td"></van-col>
                  <van-col span="10" class="t_td">{{item.itemName}}</van-col>
                  <van-col span="4" class="t_td">{{item.goodsAllocationClassName}}</van-col>
                  <van-col span="4" class="t_td_1">{{item.totalCount}}</van-col>
                  <van-col span="4" class="t_td">{{item.estimateMoney ? item.estimateMoney +'元':''}}</van-col>
                  <van-col span="1" class="t_td"></van-col>
                </van-row>
              </van-row>
            </div>

          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div v-if="auditOrCheck">
      <div class="panel radio_body withMargin">
        <p style="marginBottom:15px">
          <span :class="isChoose ? 'dot is_dot' : 'dot'"></span>
          审核结果
        </p>
        <div class="choose">
          <div :class="isPass == 1?'choose_btn pass': 'choose_btn'" @click="multipleChoice(1)">通过</div>
          <div class="line"></div>
          <div :class="isPass == 0?'choose_btn reject': 'choose_btn'" @click="multipleChoice(0)">驳回</div>
        </div>
      </div>
      <div id="cleanId" v-show="show" class="part-inputpart-row" @click="clickCleanTypeShow">
        <span :class="isCleanTypeSelect == ''? 'notdot' : 'isdot'" > </span>
        <span class="part-inputpart-row-header">原因</span>
        <span class="divide">|</span>
        <span class="part-inputpart-row-graytext" :class="isCleanTypeSelect ? 'optionSelectedColor':'' ">{{rejectReason.dictionaryTitle}}</span>
        <img class="part-inputpart-row-right" :class=" isCleanTypeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../assets/images/triangle.png">
      </div>

      <van-popup v-model="isCleanTypeShow" position="bottom">
        <van-picker
                show-toolbar
                :columns="refuseReasonList"
                @cancel="isCleanTypeShow = false"
                :default-index="refuseReasonIndex"
                value-key="dictionaryTitle"
                @confirm="selectCleanTypeOption"
        />
      </van-popup>
      <div class="panel withMargin">
        <p>审批意见</p>
        <div class="text_body">
          <van-field
                  v-model="opinion"

                  type="textarea"
                  class="mes_body"
          />
        </div>
      </div>
      <div class="sub_btn">
        <van-button :class="!isChoose || !isChoose&&!selectCleanType ?'saveButton_Disable':'saveButton_Enable'" :disabled="!isChoose || !selectCleanType" size="large" @click="save">保存</van-button>
      </div>
    </div>
  </div>
</template>
<script>
// 横向滚动组件
import BScroll from 'better-scroll';
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
import { NavBar, Tab, Tabs, Search, Row, Col, DropdownMenu, DropdownItem, Tag, Button,Overlay,Picker ,Popup,Field ,Divider ,Image as VanImage ,ImagePreview} from 'vant'
import {decorationAgree, decorationDetailInit, decorationRefuse} from "../../getData/getData";
import {getStaffId, globaluserId,responseUtil} from "../../libs/rongxunUtil";
export default {
  data () {
    return {
      refuseReasonIndex:'',
      //头部标题
      navTitle:'装修方案申请',
      //是否隐藏审核部分
      auditOrCheck:true,
      show:false,
      selectCleanType: '请选择',
      isCleanTypeShow: false,
      isCleanTypeSelect: '',
      isEmergency:false,
      isGenerally:false,
      dictionaryTitle:[],
      opinions:false,
      istype:2,
      refuseId:'',
      isReasonSelect:false,
      rejectReason:'请选择',
      //拒绝理由
      refuseReasonList: {
        // dictionaryClassName: 'refuseReason',
        // dictionaryName: 'refuseReasonOne',
        // dictionaryTitle: '拒绝理由1',
        // id: 47
      },
      guidingPrice:"",
      isPass:false,
      isReject:false,
      isSelect:'',
      isReasonShow:false,
      //硬装方案
      hardwareList:[""],
      softwareList:[""],
      //方案详情及硬装、软装方案信息
      decorationInfo:{},
      isChoose:false,
      isPass:null,
      opinion:'',
      active:0,
      baseInfo:{
      },

      stylist:{
        name:'李李李',
        phone:'2132132132'
      },
      imagesList:[
      ],
    }
  },
  name: 'ReviewOfDecorationScheme',
  components: {
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Search.name]: Search,
    [Row.name]: Row,
    [Col.name]: Col,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Overlay.name]:Overlay,
    [Popup.name]:Popup ,
    [Field.name]:Field,
    [Divider.name]:Divider ,
    [VanImage.name]:VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [BScroll.name]:BScroll,
    [Picker.name]: Picker,
    rxNavBar
  },
  mounted() {
    this.initData();
  },
  methods:{
    clickCleanTypeShow() {
      this.isCleanTypeShow = !this.isCleanTypeShow;
      var panel = document.getElementById('cleanId')
      document.addEventListener('click', e => {
        if (!panel.contains(e.target)) {
          this.isCleanTypeShow = false
        }
      })
    },
    // 人员选项
    selectCleanTypeOption(value,index) {
      this.isCleanTypeShow = false
      this.isCleanTypeSelect = true
      this.rejectReason = value
      this.refuseReasonIndex = index
    },
    selectAddressOption(event,index){
      this.rejectReason = event.path[0].childNodes[0].data
      this.isReasonShow = false
      this.isReasonSelect = true
      this.refuseId= this.refuseReasonList[index].id
    },
    previewImg(start){
      ImagePreview(
        {
          images: this.imagesList,
          startPosition: start,
          onClose() {
            // do something
          },
        }
      )
    },
    //返回上一层
    leftReturn(){
      this.$router.go(-1);
    },
    multipleChoice(isPass){
      if(isPass == 1){
        this.istype=1;
        this.show = false
      }else {
        this.istype=0;
        this.show = true
      }
      this.isChoose = true;
      this.isPass = isPass


    },

    //装修方案申请初始化
    initData(){
      let that = this;
      //如果是点击查看进入本页面，则隐藏审核按钮
      if(this.$route.params.afterRentStatus){
        that.auditOrCheck = false
        that.navTitle = '查看详情'
      }
      let initData = {};
      initData.type = '8';
      initData.id = this.$route.params.id
      // initData.id = '256'
      decorationDetailInit(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data);
          that.baseInfo = response.data.data.item
          that.decorationInfo =response.data.data.renovationMap
          that.hardwareList= response.data.data.goodsMap.selectedHardList
          that.softwareList= response.data.data.goodsMap.selectedSoftList
          that.refuseReasonList=response.data.data.refuseReasonList
          let list = response.data.data.picList
          if(list){
            for(let i=0;i<list.length;i++){
              that.imagesList.push(list[i].path)
            }
          }
          that.$nextTick(() => {
            if (!that.scroll) {
              that.scroll = new BScroll(that.$refs.wrapperBox, {
                scrollX: true,
                eventPassthrough: 'vertical'
              })
            }
          })
        })
      })
    },
    //保存
    save(){
      var that = this;
      var func;
      var data = {}
      //通过的接口
      if(this.istype == "1"){
        func = decorationAgree;
        //指导价
        console.log(this.guidingPrice)

      }
      //拒绝的接口
      if(this.istype == "0"){
        func = decorationRefuse;
        //拒绝理由id
        data.refuseReason_id=that.rejectReason.id;
      }
      data.id = that.baseInfo.id
      data.staff_id = getStaffId()//globaluserId()//that.baseInfo.approvalApplicantStaff_id
      data.approvalOpinion =that.opinion
      data.rtid =  that.baseInfo.rtid
      data.type = "8"
      console.log(data)
      func(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data)
          responseUtil.alertMsg(that,response.data.msg)
          that.leftReturn();
        })
      })
    },
  },

  created() {

  },
}

</script>
<style lang="less" scoped>
@redius:8px;
@border:1px solid rgb(244, 244, 244);

.diamod {
  width: 8px;
  height: 8px;
  background-color: #3B98FF;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 17px 7px 0 18px;
  display: inline-block;
}

.t_title {
  background-color: white;
  color:#33ADFF;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  height: 20px;
}

/*下拉菜单选项样式*/
.part-inputpart-dropDownList-option{
  text-align: center;
  line-height: 35px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: black;
  float: left;
  width: 345px;
  height: 35px;
}
/*保存按钮不可点击样式*/
.saveButton_Disable{
  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}
/*保存按钮可点击样式*/
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}
.potential .t_table .t_title[data-v-240839da] {
  //background-color: #f4f9ff;
  color: #33ADFF;
  text-align: center;
  font-weight: 700;
  font-size: 0.32rem;
  height: 0.53333rem;
}
/*圆点样式---灰色*/
.maker{
  color: #999999;
  float: left;
  font-size: 40px;
  margin: 10px 10px 10px 17px;
}
.divide {
  font-size: 14px;
  color: #efefef;
  margin:0 7px 0 10px;
}
.part-inputpart-row-graytext {
  color: #d9d9d9;
  width: 100%;
  font-size: 14px;
}
.dropDownList{
  width: 100%;
  position: absolute;
  z-index: 99;
  margin-top: 55px;
}
/*圆点样式---橘色*/
.orange{
  color: #ff5809;
  float: left;
  font-size: 40px;
  margin: 10px 10px 10px 17px;
}
.part-inputpart-row-right-downArrow{
  width: 9px;
  margin-right: 15px;
}
.optionSelectedColor{
  color: #ff5d3b;
}
.part-inputpart-dropDownList-option-selected{
  color: #ff5d3b;
}
.part-inputpart-row-normaltext{
  color: #ff5809;
}
/*选择三角图标的向上向下样式*/
.part-inputpart-row-right-upArrow{
  width: 9px;
  height: 9px;
  transform: rotateX(180deg);
  margin-right: 15px;
}
.part-inputpart-row-right-downArrow{
  width: 9px;
  height: 9px;
  margin-right: 15px;
}
.part-inputpart-dropDownList{
  width: 90%;
  position: absolute;
  left: 19px;
  z-index: 99;
  margin-top: -17px;

}
.part-inputpart-row-header{
  font-weight: bold;
  font-size: 14px;
  width: 90px;
  color: black;
}

.part-inputpart-row-right-upArrow{
  width: 9px;
  transform: rotateX(180deg);
}
.part-inputpart-row-normaltext{
  color: black;
}
.part-inputpart-row {
  width: 345px;
  float: left;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  height: 50px;
  border-radius: 8px;
  margin: 0 5px 15px 0;
}
.potential{
  width: 375px;
  background-color: rgb(250,250,250);
  /*font-size: 12px;*/
  padding: 0 15px;
  box-sizing: border-box;
  overflow: hidden;
  color: #252525;
  .sub_btn{
    margin: 30px 0 70px;
    .sub_button{
      border-radius: @redius;
      color: rgb(255,255,255);
      border: none;
      background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Firefox 3.6 - 15 */
      background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      &.not_choose{
        background: none;
        background-color: rgba(184, 184, 184, 0.2);
      }
    }
  }
  p{
    margin:0px;
  }
  .application_time{
    text-align: right;
    width: 345px;
    font-size: 12px;
    color: rgb(136,136,136);
    padding:5px  0  5px;

  }
  .subtitle{
    padding: 5px 0px;
    color: rgb(244,99,76);
    font-weight: 700;
    margin-top: 25px;
    font-size: 12px;
  }
  span{
    font-size: 13px;
  }
  .panel{
    width: 345px;
    background-color: rgb(255,255,255);
    border-radius: @redius;
    position: relative;
    /*font-size: 14px;*/
    text-align: left;
    overflow: hidden;
    &.withMargin{

    margin: 15px 0;
    }
    & > p{
      padding: 15px 15px 0px;
      font-weight: bolder;
      font-size: 14px;
    }

    & > p > span{
      font-weight: normal;
      color :#999;
    }
    .stylist{
      font-size: 13px;
      color: black;
      font-weight: bolder;
    }
    .state{
      position: absolute;
      top: 0;
      left: 0;
      font-size:20px;
      font-weight: 600;
      height:32px;
      line-height: 32px;
      width: 90px;
      display: block;
      background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Firefox 3.6 - 15 */
      background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      border-top-left-radius: 16px;
      border-bottom-right-radius: 16px;
      color: rgb(255,255,255);
      text-align: center;
      transform: scale(0.5);
      transform-origin: 0% 0%;
      font-family: sans-serif;
      padding: 0 4px;
    }
    .title{
      width: 315px;
      padding: 0 15px;
      font-weight: bolder;
      color: #262626;
      font-size: 16px;
      padding-top: 20px;
      span{
        font-weight: normal;
        color:rgba(153, 153, 153, 1);
      }
    }
    .describe{
      padding-bottom: 15px;
      padding-top: 4.5px;
      color: rgb(82,82,82);
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
    }
    .info{
      padding: 15px;
      font-size: 14px;
      line-height: 16px;
      &.noPadding{
        padding: 0 15px;
      }
      p{
        margin: 4px 0;
        font-weight: bolder;
        span{
          font-weight: normal;
          color: rgb(82,82,82);
        }
      }
    }
    .text_body{
      /*padding: 15px;*/
      padding: 10px 14px ;
      /*margin: 0 auto 6.5px;*/
      margin-bottom: 8px;
      .mes_body{
        border-radius: @redius;
        background-color: rgb(250,250,250);
      }
    }
  }
  // 图片墙
  .swiper_show{
    width: 325px;
    position: relative;
    margin: 15px 10px;
    height: 75px;
    .content{
      position: absolute;
      display: flex;
      justify-content: space-around;
      left: 0px;
      .imgWrap{
        margin-right: 10px;
      }
    }
    .num{
      position: absolute;
      left: 4px;
      top: 6px;
      font-size: 10px;
      background-color: rgb(0,0,0);
      opacity: .5;
      color: rgb(255,255,255);
      padding:2px 6px;
      display: inline-block;
      border-radius: 10px;
      font-weight: bolder;
      line-height: 10px;
    }
  }

  // 单选框
  .radio_body{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    .dot{
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: rgb(136,136,136);
      vertical-align: middle;
      margin-right: 10px;
      &.is_dot{
      background-color: rgb(251,91,64);
    }
    }
    .line{
      width: 2px;
      height: 12px;
      background-color: rgb(250,250,250);
    }
    .choose{
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      .choose_btn{
        padding: 5px;
        margin: 10px;
        color: rgb(136,136,136);
        cursor: pointer;
        &.pass{
          color: rgb(251,91,64);
          font-weight: bolder;
        }
        &.reject{
          color: red;
          font-weight: bolder;
        }
      }
    }
  }

  .notdot {
    display: inline-block;
    width: 8px;
    height: 6px;
    border-radius: 50%;
    background-color: rgb(136, 136, 136);
    vertical-align: middle;
    margin-right: 13px;
    margin-left: 15px;
  }
  .isdot{
    display: inline-block;
    width: 8px;
    height: 6px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 13px;
    background-color: rgb(251,91,64);
    margin-left: 15px;
    }
  // 循环表单
  .t_table{
    .t_header{
      text-align: left;
      color: #9a9a9a;
      /*font-weight: bolder;*/
      font-size: 10px;
      height: 20px;
      line-height: 20px;
      background-color: rgba(243, 249, 255, 1);
    }
    .t_title {
      background-color: white;
      color:#33ADFF;
      text-align: center;
      font-weight: 700;
      font-size: 12px;
      height: 20px;
    }
    .t_td{
      text-align: left;
      height: 40px;
      line-height: 40px;
      font-size: 13px !important;
      font-weight: 500;
    }
    .t_td_1{
      text-align: left;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      padding-left: 10px;
    }
  }
}
</style>
